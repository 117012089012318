import React, { useState, useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios';
import SearchBar from './SearchBar';
import ImageGallery from './ImageGallery';
import { useSelector, useDispatch } from 'react-redux';

import { showLoading, hideLoading } from '../../store/slices/loadingState';

import { v4 as uuid } from 'uuid';
import S3 from 'react-aws-s3';
import swal from 'sweetalert2';

import {
  API_GET_CS_IMAGES,
  API_DELETE_CS_IMAGE,
  BUCKET_CS_IMAGES_UPLOAD,
  AWS_REGION,
  AWS_MMS_S3_API_KEY,
  AWS_MMS_S3_ACCESS_KEY,
  API_KEY,
  UPDATE_STOCK_UPLOAD_IMAGES
} from '../../utils/constants';
const Images = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [strategySelected, setStrategySelected] = useState(null);
  const config = {
    dirName: `${strategySelected?.value}` /*optional */,
    bucketName: BUCKET_CS_IMAGES_UPLOAD,
    region: AWS_REGION,
    accessKeyId: AWS_MMS_S3_API_KEY,
    secretAccessKey: AWS_MMS_S3_ACCESS_KEY,
    s3Url: `https://${BUCKET_CS_IMAGES_UPLOAD}.s3.${AWS_REGION}.amazonaws.com`
  };
  const [files, setFiles] = useState({ length: 0 });
  const [uploadingImages, setUploadingImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const dispatch = useDispatch();

  const handleUpload = async (file) => {
    let newFileName = uuid();
    const ReactS3Client = new S3(config);
    let { location } = await ReactS3Client.uploadFile(file, newFileName);
    return location;
  };

  useEffect(() => {
    const preselectedStrategy = {
      label: 'Residential/Commercial Cleaning',
      value: 'ed05cf1fbe1e48d056381b3c8e3c34bce621151806b5d9885025ef35d0e891f7'
    };
    handleSelectStrategy(preselectedStrategy);
  }, []);

  const renderImages = (files) => {
    let i = 0;
    let itemsList = [];
    while (i < files.length) {
      itemsList[i] = files[i];
      i++;
    }
    itemsList = itemsList.map((item, index) => {
      function returnFileSize(number) {
        if (number < 1024) {
          return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
          return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
          return (number / 1048576).toFixed(1) + 'MB';
        }
      }
      function handleName(name) {
        if (name.length > 35) {
          return name.slice(0, 35) + ' ...';
        } else return name;
      }
      return (
        <div
          key={index}
          className="card"
          style={{
            width: '48%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 5,
              marginTop: 5,
              overflow: 'hidden'
            }}>
            <b>FileName: </b>
            <u>{handleName(item.name)}</u>
            <b>Size: </b>
            {returnFileSize(item.size)}
          </div>
          <div>
            <img
              alt=""
              src={URL.createObjectURL(item)}
              style={{ width: 100, height: 100, objectFit: 'cover' }}
            />
          </div>
        </div>
      );
    });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flexFlow: 'row wrap',
          alignContent: 'flex-end',
          justifyContent: 'space-between'
        }}>
        {itemsList}
      </div>
    );
  };
  const handleSelectStrategy = async (strategy) => {
    setStrategySelected(strategy);
    dispatch(showLoading());
    setLoading(true); // Iniciar la carga
    try {
      const response = await axios.get(
        `${API_GET_CS_IMAGES}/${strategy.value}`
      );
      setImages(response.data); // Suponiendo que la respuesta es un array con las imágenes
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      dispatch(hideLoading());
      setLoading(false); // Terminar la carga
    }
  };

  const handleDeleteImage = async (imageToDelete) => {
    try {
      let config = {
        method: 'post',
        url: API_DELETE_CS_IMAGE,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          contentStrategyId: imageToDelete.PK,
          sourceUrl: imageToDelete.SK
        })
      };
      await axios(config);
      setImages(images.filter((image) => image.SK !== imageToDelete.SK));
    } catch (error) {
      console.error('Error deleting image:', error);
    } finally {
    }
  };

  return (
    <>
      <div className="content ht-100v pd-0">
        <div className="content-header">
          <div className="content-search">
            <SearchBar onSelectClient={handleSelectStrategy} />
          </div>
        </div>

        <div className="content-body">
          <form>
            <input
              style={{
                visibility: 'hidden',
                position: 'absolute'
              }}
              id="image_uploads"
              onChange={(e) => {
                setFiles(e.target.files);
              }}
              type="file"
              multiple
              accept="image/png, image/jpeg"
            />
            {(files?.length && showUploadModal) > 0 ? (
              <div
                className="form-group"
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <React.Fragment>
                  <div className="modal-backdrop in">
                    <div
                      className="modal fade in show"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      style={{ display: 'block' }}>
                      <div
                        className="modal-dialog modal-dialog-centered modal-lg tx-2"
                        role="document">
                        <div className="modal-content tx-12">
                          <div className="modal-header">
                            <h5 className="modal-title" id="addNewGroupLabel">
                              Upload Images ({files?.length})
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setFiles({ length: 0 })}
                              disabled={uploadingImages}>
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div
                              className="card"
                              style={{
                                maxHeight: '500px',
                                minHeight: '325px',
                                overflow: 'scroll'
                              }}>
                              <div className="card-body">
                                <div>{renderImages(files)}</div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              disabled={uploadingImages}
                              className="btn btn-danger tx-13"
                              data-dismiss="modal"
                              onClick={() => {
                                setShowUploadModal(false);
                              }}>
                              Cancel
                            </button>
                            <button
                              type="button"
                              disabled={uploadingImages || files?.length === 0}
                              className="btn btn-primary"
                              onClick={async () => {
                                setUploadingImages(true);
                                config.name = files[0].name;
                                setProgress(0);
                                let newArr = files;
                                for (let i = 0; i < newArr.length; i++) {
                                  let location = await handleUpload(newArr[i]);
                                  axios({
                                    method: 'post',
                                    url: UPDATE_STOCK_UPLOAD_IMAGES,
                                    data: {
                                      csId: strategySelected?.value,
                                      sourceUrl: location,
                                      source: 'manual_upload',
                                      keywords: ''
                                    },
                                    headers: {
                                      'X-API-KEY': API_KEY,
                                      'Content-Type': 'application/json'
                                    }
                                  });
                                  if (i !== 0) {
                                    let pr = (i / files.length) * 100;
                                    setProgress(Math.round(pr));
                                  } else {
                                    setProgress(0);
                                  }
                                }
                                setTimeout(async () => {
                                  setUploadingImages(false);
                                  handleSelectStrategy(strategySelected);
                                  setFiles({});
                                  setProgress(0);
                                  swal.fire('Success!', 'Uploaded', 'success');
                                }, 3000);
                              }}>
                              <span
                                className={`spinner-border spinner-border-sm mr-1 ${
                                  !uploadingImages ? 'hidden' : ''
                                }`}
                                role="status"
                                aria-hidden="true"></span>
                              {uploadingImages
                                ? 'Uploading...' +
                                  `${progress !== 0 ? progress : '0'}%`
                                : 'Upload'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            ) : null}
          </form>

          <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                  <li class="breadcrumb-item">
                    <a href="/clients">Clients</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Images
                  </li>
                </ol>
              </nav>
              <h4 class="mg-b-0 tx-spacing--1">Image Library</h4>
            </div>
            <div class="d-none d-md-block">
              <label
                style={{
                  margin: '12px',
                  visibility: strategySelected?.label ? 'visible' : 'hidden'
                }}
                htmlFor="image_uploads"
                className="btn btn-sm  btn-primary mg-l-10"
                onClick={() => {
                  setShowUploadModal(true);
                }}>
                <i data-feather="file" class="wd-10 mg-r-5"></i> Upload
              </label>
              <span class="tx-medium mg-l-5">
                {' '}
                Total Images:{images.length}
              </span>
            </div>
          </div>

          <div className="card">
            <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
              <h6 className="tx-uppercase tx-semibold mg-b-0">
                {strategySelected?.label}
              </h6>
            </div>
            <div className="content pe-5">
              <ImageGallery
                isPage
                strategyName={strategySelected?.label}
                images={images}
                onDeleteImage={(image) => handleDeleteImage(image)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Images;
