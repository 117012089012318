import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import Swal from 'sweetalert2';
import axios from 'axios';
import { AWS_REGION, S3_CLIENTS_IMAGES } from '../../utils';
import './style.css';

function EditImgModal({ handleClose, img, onUpdate, clientId, storeOriginal }) {
  const [image, setImage] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    storeOriginal(img);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img]);

  const uploadImgToS3 = async (img) => {
    setLoading(true);

    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'image/*'
      }
    };
    console.log(`S3_CLIENTS_IMAGES: ${S3_CLIENTS_IMAGES}`);
    let imageUrl = img.split(`/${S3_CLIENTS_IMAGES}/`)[1];
    console.log(`imageUrl: ${imageUrl}`);

    if (imageUrl === undefined) {
      console.log('undefined');
      imageUrl = `${img}?time=${new Date().getTime()}`;
    } else {
      console.log(
        `https://${S3_CLIENTS_IMAGES}.s3.${AWS_REGION}.amazonaws.com/${imageUrl}`
      );
      imageUrl = `https://${S3_CLIENTS_IMAGES}.s3.${AWS_REGION}.amazonaws.com/${imageUrl}?time=${new Date().getTime()}`;
    }

    await axios
      .get(imageUrl, config)
      .then((response) => {
        if (response.data) {
          const objectURL = URL.createObjectURL(response.data);
          setImage(objectURL);
          setLoading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: 'The url is not valid!'
        }).then((res) => {
          if (res.value) {
            setImage('');
          }
        });
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (img) {
      if (img.split(':')[0] === 'blob') {
        setImage(img);
      } else {
        uploadImgToS3(img);
      }
    }
    // eslint-disable-next-line
  }, [img]);

  useEffect(() => {
    if (croppedImage) {
      onUpdate(croppedImage);
    }
  }, [croppedImage, onUpdate]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image, handleClose]);

  const zoomRange = () => {
    return (
      <React.Fragment>
        <label htmlFor="customRange1">
          Zoom {Math.floor((zoom * 100) / 3)} %
        </label>
        <input
          type="range"
          className="custom-range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e) => setZoom(e.target.value)}
        />
      </React.Fragment>
    );
  };

  const rotationRange = () => {
    return (
      <React.Fragment>
        <label htmlFor="customRange1">Rotation {rotation} °</label>
        <input
          type="range"
          className="custom-range"
          value={rotation}
          min={0}
          max={360}
          step={1}
          onChange={(e) => setRotation(e.target.value)}></input>
      </React.Fragment>
    );
  };

  const aspectRatio = () => {
    const ratios = ['1:1', '3:4', '4:3', '9:16', '16:9', '9:21', '21:9'];
    return (
      <div className="mb-3">
        <label>Aspect ratio</label>
        <div className="d-flex flex-wrap">
          {ratios.map((ratio, idx) => {
            let nums = ratio.split(':');
            let calc = nums[0] / nums[1];
            return (
              <div className="custom-control custom-radio m-2" key={idx}>
                <input
                  type="radio"
                  id={`customRadio${ratio}`}
                  name={'ratio'}
                  className="custom-control-input"
                  onChange={() => setAspect(calc)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customRadio${ratio}`}>
                  {ratio}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="modal-backdrop in"></div>
      <div className="modal show d-block" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-edit modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Image</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleClose()}></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div
                  className="col-md-8"
                  style={{
                    height: '24rem',
                    maxWidth: '100%',
                    overflow: 'hidden'
                  }}>
                  <div
                    className="crop-container"
                    style={{
                      width: '100%', // Limita el ancho al contenedor padre
                      height: '100%', // Se ajusta a la altura disponible
                      overflow: 'hidden' // Evita el desbordamiento
                    }}>
                    {loading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        style={{
                          mediaClassName: 'crop-class',
                          maxWidth: '100%',
                          width: '100%',
                          height: '100%'
                        }}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="controls mt-3">
                    {aspectRatio()}
                    {zoomRange()}
                    {rotationRange()}
                  </div>
                  <div className="save-btns">
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      onClick={() => handleClose()}>
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary ml-1"
                      onClick={() => {
                        showCroppedImage();
                      }}>
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditImgModal;
