// Layout.js
import React from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from './store/slices/auth';
// import LogOut from '../../components/LogOut';
import LogOut from './components/LogOut';
import { NotificationsGrid } from './components/Notifications';

import { Link, useLocation, useHistory } from 'react-router-dom';

const Layout = ({ children }) => {
  const { loading } = useSelector((state) => state.loadingState);
  const { userInfo } = useSelector(authSelector);
  const location = useLocation();
  const history = useHistory();

  const checkActive = (route) => (location.pathname === route ? 'active' : '');

  return (
    <>
      {loading && (
        <div
          className="progress position-fixed w-100"
          style={{
            height: '3px',
            left: 0,
            zIndex: 9999,
            borderRadius: 0
          }}>
          <div className="progress-bar indeterminate" />
        </div>
      )}
      <aside className="aside aside-fixed">
        <div className="aside-header">
          <Link to="/clients" className="aside-logo">
            <img
              src="https://development.d10o6wx5z0ywdo.amplifyapp.com/adlerLocal-logo.svg"
              style={{ width: '150px', height: 'auto' }}
              alt="Adler Logo"
            />
          </Link>
          <a href="" className="aside-menu-link">
            <i data-feather="menu"></i>
            <i data-feather="x"></i>
          </a>
        </div>
        <div className="aside-body">
          <div className="aside-loggedin">
            <div className="d-flex align-items-center justify-content-start">
              <a href="" className="avatar">
                <img
                  src={userInfo.photoURL}
                  className="rounded-circle"
                  alt=""
                />
              </a>
              <div className="aside-alert-link">
                <NotificationsGrid />
                <LogOut />
                {/* <a href="" data-bs-toggle="tooltip" title="Sign out">
                  <i data-feather="log-out"></i>
                </a> */}
              </div>
            </div>
            <div className="aside-loggedin-user">
              <a
                href="#loggedinMenu"
                className="d-flex align-items-center justify-content-between mg-b-2"
                data-bs-toggle="collapse">
                <h6 className="tx-semibold mg-b-0">{userInfo.displayName}</h6>
              </a>
              <p className="tx-color-03 tx-12 mg-b-0">
                {userInfo.role === 'admin' ? 'Administrator' : userInfo.role}
              </p>
            </div>
          </div>
          <ul className="nav nav-aside">
            <li className="nav-label">Dashboard</li>
            <li className={`nav-item ${checkActive('/clients')}`}>
              <Link to="/clients" className="nav-link">
                <i data-feather="home"></i> <span>Clients</span>
              </Link>
            </li>
            <li className={`nav-item ${checkActive('/reviews')}`}>
              <Link to="/reviews" className="nav-link">
                <i data-feather="star"></i> <span>Reviews</span>
              </Link>
            </li>
            <li className={`nav-item ${checkActive('/content-strategies')}`}>
              <Link to="/content-strategies" className="nav-link">
                <i data-feather="book"></i> <span>Content</span>
              </Link>
            </li>
            <li className={`nav-item ${checkActive('/images')}`}>
              <Link to="/images" className="nav-link">
                <i data-feather="image"></i> <span>Images</span>
              </Link>
            </li>
            <li className={`nav-item ${checkActive('/logs')}`}>
              <Link to="/logs" className="nav-link">
                <i data-feather="mail"></i> <span>Notifications</span>
              </Link>
            </li>
          </ul>

          <div class="aside-body-footer">
            <span>&copy; 2024 Adler Social LLC</span>
          </div>
        </div>
      </aside>

      {children}
    </>
  );
};

export default Layout;
