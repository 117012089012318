import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import './DateFilter.css'; // Importa tu archivo de estilos CSS

const DateFilter = ({
  userInfo,
  onFilterChange,
  defaultDates,
  accountManagers,
  plans,
  modules,
  filter,
  loading
}) => {
  const [startDate, setStartDate] = useState(
    new Date(defaultDates.startDate) || null
  );
  const [endDate, setEndDate] = useState(
    new Date(defaultDates.endDate) || null
  );

  // Calculamos la fecha de 30 días atrás
  const today = new Date();

  const handleFilterChange = (val) => {
    if (startDate && endDate) {
      const filterObject = {
        ...filter,
        startDate: Math.floor(startDate.getTime() / 1000),
        endDate: Math.floor(endDate.getTime() / 1000)
      };
      onFilterChange(filterObject);
    } else {
      // Manejar el caso cuando no se han seleccionado ambas fechas
      console.error('Seleccione ambas fechas');
    }
    if (val?.filter === 'stars')
      onFilterChange((prevValue) => {
        return { ...filter, stars: val.value };
      });
    if (val?.filter === 'manager')
      onFilterChange((prevValue) => {
        return { ...filter, manager: val.value };
      });
    if (val?.filter === 'status')
      onFilterChange((prevValue) => {
        return { ...filter, status: val.value };
      });

    if (val?.filter === 'tier')
      onFilterChange((prevValue) => {
        return { ...filter, tier: val.value };
      });

    if (val?.filter === 'email')
      onFilterChange((prevValue) => {
        return { ...filter, email: val.value };
      });

    if (val?.filter === 'type')
      onFilterChange((prevValue) => {
        return { ...filter, type: val.value };
      });
  };
  const handleFilterClear = () => {
    onFilterChange({});
  };

  return (
    <div className="d-flex align-items-center justify-content-around px-4 py-3">
      <div className="d-flex align-items-center">
        <label className="mx-2 h-100" style={{ color: '#8392a5' }}>
          Date:
        </label>
        <DateTimePicker
          onChange={(date) => setStartDate(date)}
          value={startDate}
          maxDate={today} // Limite máximo es la fecha actual
          placeholder="Start Date"
          format="yyyy-MM-dd"
          clearIcon={null} // Deshabilita el botón de limpieza
          calendarIcon={null}
          className="custom-datetime-picker"
        />

        <label className="mx-2">-</label>
        <DateTimePicker
          onChange={(date) => setEndDate(date)}
          value={endDate}
          maxDate={today} // Limite máximo es la fecha actual
          placeholder="End Date"
          format="yyyy-MM-dd"
          clearIcon={null} // Deshabilita el botón de limpieza
          calendarIcon={null}
          className="custom-datetime-picker"
        />
      </div>
      {userInfo.email == 'christian@samxlabs.com' ||
      userInfo.email == 'daniel@samxlabs.com' ||
      userInfo.email == 'nicolas@samxlabs.com' ||
      userInfo.email == 'alejandro@samxlabs.com' ? (
        <div className="d-flex align-items-center">
          <label className="mx-2" style={{ color: '#8392a5' }}>
            Account Manager:
          </label>
          <Select
            isDisabled={loading}
            styles={{
              ...customStyles,
              ...{
                container: (provided) => ({
                  ...provided,
                  width: '120px' // Ejemplo: ancho de 200px
                })
              }
            }}
            value={
              filter?.email
                ? {
                    value: filter?.email,
                    label: accountManagers.find(
                      (item) => item.email === filter?.email
                    ).full_name
                  }
                : { value: '0', label: 'All' }
            }
            onChange={(val) =>
              handleFilterChange({ filter: 'email', value: val.value })
            }
            defaultValue={{ value: '0', label: 'All' }}
            options={accountManagers.map((option, idx) => ({
              value: option.email,
              label: option.full_name
            }))}
          />
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <label className="mx-2" style={{ color: '#8392a5' }}>
            Account Manager:
          </label>
          <Select
            isDisabled={true}
            styles={{
              ...customStyles,
              ...{
                container: (provided) => ({
                  ...provided,
                  width: '120px' // Ejemplo: ancho de 200px
                })
              }
            }}
            value={{ value: '0', label: userInfo.email }}
            onChange={(val) =>
              handleFilterChange({ filter: 'email', value: val.value })
            }
          />
        </div>
      )}
      <div className="d-flex align-items-center">
        <label className="mx-2" style={{ color: '#8392a5' }}>
          Module:
        </label>
        <Select
          isDisabled={loading}
          styles={{
            ...customStyles,
            ...{
              container: (provided) => ({
                ...provided,
                width: '120px' // Ejemplo: ancho de 200px
              })
            }
          }}
          value={
            filter?.type
              ? {
                  value: filter?.type,
                  label: modules.find((item) => item.id === filter?.type).text
                }
              : { value: '0', label: 'All' }
          }
          onChange={(val) =>
            handleFilterChange({ filter: 'type', value: val.value })
          }
          defaultValue={{ value: '0', label: 'All' }}
          options={modules.map((option, idx) => ({
            value: option.id,
            label: option.text
          }))}
        />
      </div>

      <div className="d-flex align-items-center">
        <label className="mx-2" style={{ color: '#8392a5' }}>
          Plan:
        </label>
        <Select
          isDisabled={loading}
          styles={{
            ...customStyles,
            ...{
              container: (provided) => ({
                ...provided,
                width: '120px' // Ejemplo: ancho de 200px
              })
            }
          }}
          value={
            filter?.tier
              ? {
                  value: filter?.tier,
                  label: plans.find((item) => item.PK === filter?.tier).text
                }
              : { value: '0', label: 'All' }
          }
          onChange={(val) =>
            handleFilterChange({ filter: 'tier', value: val.value })
          }
          defaultValue={{ value: '0', label: 'All' }}
          options={plans.map((option, idx) => ({
            value: option.PK,
            label: option.text
          }))}
        />
      </div>

      <div className="d-flex align-items-center">
        <button
          className="btn btn-sm pd-x-15 btn-primary mg-l-5"
          disabled={loading}
          onClick={handleFilterChange}>
          Filter
        </button>
        {filter?.startDate ||
        filter?.endDate ||
        filter?.type ||
        filter?.tier ? (
          <button
            className="btn btn-sm pd-x-15 btn-secondary mg-l-5"
            disabled={loading}
            onClick={handleFilterClear}>
            Clear
          </button>
        ) : null}
      </div>
    </div>
  );
};
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '60px'
  }),
  control: (provided) => ({
    ...provided,
    maxHeight: '25px',
    minHeight: '8px'
  }),
  menu: (provided) => ({
    ...provided
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3b5998' : 'white'
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '2px',
    paddingLeft: '1px'
  }),

  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  })
};

export default DateFilter;
