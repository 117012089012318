import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from './components/ProtectedRoute';
import ClientsContextProvider from './contexts/ClientsContext';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Calendar from './pages/Calendar';
import Client from './pages/Client';
import Groups from './pages/Groups';
import Reviews from './pages/Reviews';
import EventLogs from './pages/eventLogs';
import ContentStrategies from './pages/ContentStrategies';
import Logs from './pages/Logs';
import Images from './pages/Images';
import {
  AssignmentsMain,
  AssignmentMonthlyReport,
  AssignmentDailyReport
} from './pages/AssignmentPage';
import Notifications from './pages/Notifications';
import Mobile from './components/AppRegister/Mobile';
import { authSelector, validateUserAuth } from './store/slices/auth';
import NotFound from './pages/NotFound';
import { firebaseAuth } from './firebase/firebase';
import { SHOW_LOGS } from './utils';
import NewClient from './components/NewClientOnboarding';

function App(props) {
  const { isAuthenticated, userInfo } = useSelector(authSelector);
  const dispatch = useDispatch();
  const script = document.createElement('script');
  script.src = '../../../assets/js/dashforge.js';
  script.type = 'text/javascript';
  script.async = true;
  document.body.appendChild(script);

  const { isVerifying, user } = props;
  const allowedDomains = ['@samxlabs.com', '@adlersocial.com'];
  useEffect(() => {
    const unsusbscribe = firebaseAuth.onIdTokenChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        dispatch(validateUserAuth(user, idToken));
      }
    });

    return () => {
      unsusbscribe();
    };
  }, [dispatch]);

  return (
    <ClientsContextProvider>
      <Switch>
        {/* Resto de las rutas dentro del Layout */}

        <ProtectedRoute
          exact
          path="/clients"
          component={Dashboard}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          exact
          path="/calendar/:id"
          component={Calendar}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/mobile/"
          component={Mobile}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/client/:id"
          component={Client}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/groups"
          component={Groups}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/reviews"
          component={Reviews}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/logs"
          component={EventLogs}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        {/* Assignments Routes */}
        <ProtectedRoute
          exact
          path="/assignment"
          component={AssignmentsMain}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/assignment/monthly/:month/:year"
          component={AssignmentMonthlyReport}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/assignment/daily/:contentCreator/:date"
          component={AssignmentDailyReport}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/assignment/daily/:date"
          component={AssignmentDailyReport}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        {/* Notifications Routes */}
        <ProtectedRoute
          exact
          path="/notifications/"
          component={Notifications}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/notifications/:notificationId"
          component={Notifications}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          user={user}
        />
        <ProtectedRoute
          exact
          path="/content-strategies"
          component={ContentStrategies}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          exact
          path="/images"
          component={Images}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          exact
          path="/new-client-onboarding"
          component={NewClient}
          isAuthenticated={isAuthenticated}
        />

        <Route exact path="/" component={Login} />
        <Route path="*" component={NotFound} />
      </Switch>
    </ClientsContextProvider>
  );
}

export default App;
