export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

export const timeZones = [
  { id: 1, name: 'Eastern Daylight Time - (GMT-4)' },
  { id: 2, name: 'Central Daylight Time - (GMT-5)' },
  { id: 3, name: 'Mountain Daylight Time - (GMT-6)' },
  { id: 4, name: 'Mountain Standard Time - (GMT-7)' },
  { id: 5, name: 'Pacific Daylight Time - (GMT-7)' },
  { id: 6, name: 'Alaska Daylight Time - (GMT-8)' },
  { id: 7, name: 'Hawaii-Aleutian Standard Time - (GMT-10)' }
];

export const daysOrder = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7
};

export const BUSINESSHOURS = [
  {
    value: 1,
    label: '1 AM'
  },
  {
    value: 2,
    label: '2 AM'
  },
  {
    value: 3,
    label: '3 AM'
  },
  {
    value: 4,
    label: '4 AM'
  },
  {
    value: 5,
    label: '5 AM'
  },
  {
    value: 6,
    label: '6 AM'
  },
  {
    value: 7,
    label: '7 AM'
  },
  {
    value: 8,
    label: '8 AM'
  },
  {
    value: 9,
    label: '9 AM'
  },
  {
    value: 10,
    label: '10 AM'
  },
  {
    value: 11,
    label: '11 AM'
  },
  {
    value: 12,
    label: '12 PM'
  },
  {
    value: 13,
    label: '1 PM'
  },
  {
    value: 14,
    label: '2 PM'
  },
  {
    value: 15,
    label: '3 PM'
  },
  {
    value: 16,
    label: '4 PM'
  },
  {
    value: 17,
    label: '5 PM'
  },
  {
    value: 18,
    label: '6 PM'
  },
  {
    value: 19,
    label: '7 PM'
  },
  {
    value: 20,
    label: '8 PM'
  },
  {
    value: 21,
    label: '9 PM'
  },
  {
    value: 22,
    label: '10 PM'
  },
  {
    value: 23,
    label: '11 PM'
  },
  {
    value: 0,
    label: '12 AM'
  }
];

export const accountManagers = [
  {
    id: '0',
    full_name: 'All',
    email: null
  },
  {
    id: '3359678000000161021',
    full_name: 'Stefan Singer',
    email: 'stefan@adlersocial.com'
  },
  {
    id: '3359678000000189001',
    full_name: 'Claire Sabando',
    email: 'claire@adlersocial.com'
  },
  {
    id: '3359678000002719001',
    full_name: 'Dj Hargrove',
    email: 'dj@adlersocial.com'
  },
  {
    id: '3359678000003451001',
    full_name: 'Robin Lincoln-Codjoe',
    email: 'robin@adlersocial.com'
  },
  {
    id: '3359678000043808001',
    full_name: 'Esther Rowe',
    email: 'esther@adlersocial.com'
  },
  {
    id: '3359678000046853103',
    full_name: 'Daniel Dominguez',
    email: null
  },
  {
    id: '3359678000057925001',
    full_name: 'Paula Halfond',
    email: null
  },
  {
    id: '3359678000094902026',
    full_name: 'Jose Valera',
    email: 'jose@adlersocial.com'
  },
  {
    id: '3359678000157782001',
    full_name: 'Lauren Turkovic',
    email: 'laurent@adlersocial.com'
  },
  {
    id: '3359678000163193001',
    full_name: 'Michael Ognar-Pettersson',
    email: null
  },
  {
    id: '3359678000163912001',
    full_name: 'Heather DeMorris',
    email: 'heather@adlersocial.com'
  },
  {
    id: '3359678000164337001',
    full_name: 'Phillippa Sirdar',
    email: 'phillippa@adlersocial.com'
  },
  {
    id: '3359678000176335001',
    full_name: 'Deborah Moxam',
    email: null
  },
  {
    id: '3359678000177646027',
    full_name: 'DeAnnie Baker',
    email: 'deannie@adlersocial.com'
  },
  {
    id: '3359678000177656001',
    full_name: 'Jorge Ramirez',
    email: 'jorge@adlersocial.com'
  },
  {
    id: '3359678000182709001',
    full_name: 'Christiana Daskalakis',
    email: 'christiana@adlersocial.com'
  },
  {
    id: '3359678000195266001',
    full_name: 'Zachary Marsh',
    email: 'zachary@adlersocial.com'
  },
  {
    id: '3359678000195269001',
    full_name: 'Emily Betancourt',
    email: 'emily@adlersocial.com'
  },
  {
    id: '3359678000000186001',
    full_name: 'Elizabeth College',
    email: null
  },
  {
    id: '3359678000000187001',
    full_name: 'Elliot Valentine',
    email: null
  },
  {
    id: '3359678000000189007',
    full_name: 'Intern Adler',
    email: 'intern@adlersocial.com'
  },
  {
    id: '3359678000000191001',
    full_name: 'Senior Content Team',
    email: null
  },
  {
    id: '3359678000000192001',
    full_name: 'Taylor Higgins',
    email: null
  },
  {
    id: '3359678000002751001',
    full_name: 'Bryan Pfingst',
    email: null
  },
  {
    id: '3359678000002752001',
    full_name: 'Jessica Zepp',
    email: null
  },
  {
    id: '3359678000003130001',
    full_name: 'Jordan Hamilton',
    email: null
  },
  {
    id: '3359678000004375001',
    full_name: 'Jake Soules',
    email: null
  },
  {
    id: '3359678000004647001',
    full_name: 'Emmy Petit-Frere',
    email: null
  },
  {
    id: '3359678000013466399',
    full_name: 'Erica Garshell',
    email: null
  },
  {
    id: '3359678000198254001',
    full_name: 'Vasariah Guerrier',
    email: 'vasariah@adlersocial.com'
  },
  {
    id: '3359678000187275001',
    full_name: 'Rafael Guerrero',
    email: null
  },
  {
    id: '3359678000162469001',
    full_name: 'Valentina Leon',
    email: null
  },
  {
    id: '3359678000130258001',
    full_name: 'Kayla Johnson',
    email: null
  },
  {
    id: '3359678000111963001',
    full_name: 'Kayla Johnson',
    email: null
  },
  {
    id: '3359678000043785004',
    full_name: 'Shelly Blanton',
    email: null
  },
  {
    id: '3359678000087015001',
    full_name: 'Content Team One',
    email: null
  },
  {
    id: '3359678000092094423',
    full_name: 'Carla Myers',
    email: null
  }
];

export const PLANS = [
  { PK: 'pro', text: 'Pro Plan' },
  { PK: 'plus', text: 'Plus Plan' },
  { PK: 'elite', text: 'Elite Plan' },
  { PK: 'starter', text: 'Starter Plan' }
];

export const MODULES = [
  { id: 'GBP', text: 'GBP' },
  { id: 'APP', text: 'APP' }
];
