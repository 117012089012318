import React, { useEffect, useState } from 'react';

import axios from 'axios';
import DataTables from '../../components/DataTables';
import './style.css';
import DateFilter from './Filters/DateFilter';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { authSelector } from '../../store/slices/auth';
import ModalInfo from './ModalInfo';
import { showLoading, hideLoading } from '../../store/slices/loadingState';

import { REACT_APP_API_NOTIFICATIONS } from '../../utils';

import { accountManagers, PLANS, MODULES } from '../../utils';

const EventLogs = (props) => {
  const { userInfo } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [highlightedRow, setHighlightedRow] = useState(null);

  const defaultDates = {
    startDate: getMonthAgoUnix(),
    endDate: getCurrentUnix()
  };

  function getMonthAgoUnix() {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return Math.floor(today.getTime()); // Convertir a segundos
  }

  function getCurrentUnix() {
    return Math.floor(new Date().getTime()); // Convertir a segundos
  }

  const notifications = useSelector(
    (state) => state?.notifications?.notifications
  );
  const dispatch = useDispatch();

  const setStateReviews = (notifications) => {
    dispatch({
      type: 'notifications/setNotifications',
      payload: notifications
    });
  };

  const handleFilterChange = (newFilter) => {
    console.log('newFilter: ', newFilter);
    setFilter(newFilter);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNotifications = async (queryParams) => {
    setStateReviews([]);
    dispatch(showLoading());

    let url = `${REACT_APP_API_NOTIFICATIONS}/all`;
    if (!queryParams.email) {
      queryParams = { ...queryParams, email: userInfo.email };
    }

    axios.get(url, { params: { ...queryParams } }).then((res) => {
      console.log(res?.data);
      let notifications = res.data;
      setStateReviews(notifications);
      dispatch(hideLoading());
    });
  };

  useEffect(() => {
    if (filter !== null) {
      console.log('changed', filter);
    }
    getNotifications({ ...filter });
  }, [filter]);

  const handleShowModal = (info) => {
    setShowModal(!showModal);
    if (info) {
      setModalInfo(info);
    } else {
      setModalInfo(null);
    }
  };

  const DateFormat = (cell, row) => {
    if (cell && !row.rejected) {
      return new Date(cell).toLocaleDateString();
    } else {
      return <>--</>;
    }
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.is_read === false) {
      style.fontWeight = 'bold';
    }

    if (rowIndex === highlightedRow) {
      style.fontWeight = 'normal'; // Cambia este color según prefieras
    }

    return style;
  };

  const handleCheckNotification = async (row, rowIndex) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        id: row.notificationId
      })
    };

    await fetch(`${REACT_APP_API_NOTIFICATIONS}`, options);

    setHighlightedRow(rowIndex);
    row.is_read = true;
  };

  const clientColumns = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'name',
      headerStyle: () => {
        return {
          width: '281px',
          textAlign: 'left'
        };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            key={row.clientId + uuid()}>
            <span
              style={{
                cursor: 'pointer',
                color: '#3b5998'
              }}
              onClick={() => {
                handleShowModal(row);
                handleCheckNotification(row, rowIndex);
              }}>
              {cell ? cell : 'undefined'}
            </span>
          </div>
        );
      }
    },
    {
      dataField: 'createdAt',
      text: 'date',
      headerStyle: () => {
        return {
          width: '110px',
          textAlign: 'left'
        };
      },
      formatter: DateFormat
    },
    {
      dataField: 'tier',
      text: 'plan',
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'left'
        };
      }
    },
    {
      dataField: 'time',
      text: 'time',
      headerStyle: () => {
        return {
          width: '110px',
          textAlign: 'left'
        };
      },
      formatter: (cell, row) => {
        return new Date(cell).toLocaleTimeString();
      }
    },
    {
      dataField: 'type',
      text: 'module',
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'left'
        };
      }
    },
    {
      dataField: 'title',
      text: 'message text',
      formatter: (cellContent) => (
        <div dangerouslySetInnerHTML={{ __html: cellContent }} />
      )
    }
  ];

  return (
    <>
      <div className="content ht-100v pd-0 datatable-content-body">
        <DataTables
          buttonsCallback={() => {}}
          userRole={userInfo.role}
          data={notifications}
          rowStyle={rowStyle}
          columns={clientColumns}
          noDataIndication={loading ? 'Loading...' : 'No Notifications'}
          alternTitle="notifications"
          title={`Notifications ${
            notifications?.length > 0 ? `(${notifications?.length})` : ``
          }`}
          loading={loading}
          showTotal={false}
          view={'notifications'}>
          <DateFilter
            userInfo={userInfo}
            filter={filter}
            defaultDates={defaultDates}
            onFilterChange={handleFilterChange}
            accountManagers={accountManagers}
            plans={PLANS}
            modules={MODULES}
            loading={loading}
          />
        </DataTables>
        {showModal && (
          <ModalInfo handleClose={handleShowModal} logInfo={modalInfo} />
        )}
      </div>
    </>
  );
};

export default EventLogs;
