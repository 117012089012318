import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Image from './Image';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import S3 from 'react-aws-s3';
import swal from 'sweetalert2';
import {
  API_GET_CS_IMAGES,
  API_DELETE_CS_IMAGE,
  API_KEY,
  BUCKET_CS_IMAGES_UPLOAD,
  AWS_REGION,
  AWS_MMS_S3_API_KEY,
  AWS_MMS_S3_ACCESS_KEY,
  UPDATE_STOCK_UPLOAD_IMAGES
} from '../../utils/constants';
const StrategyImages = ({ showModal, handleClose, defaultData }) => {
  const config = {
    dirName: `${defaultData?.id}` /*optional */,
    bucketName: BUCKET_CS_IMAGES_UPLOAD,
    region: AWS_REGION,
    accessKeyId: AWS_MMS_S3_API_KEY,
    secretAccessKey: AWS_MMS_S3_ACCESS_KEY,
    s3Url: `https://${BUCKET_CS_IMAGES_UPLOAD}.s3.${AWS_REGION}.amazonaws.com`
  };
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState({ length: 0 });
  const [uploadingImages, setUploadingImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleUpload = async (file) => {
    let newFileName = uuid();
    const ReactS3Client = new S3(config);
    let { location } = await ReactS3Client.uploadFile(file, newFileName);
    return location;
  };

  const renderImages = (files) => {
    let i = 0;
    let itemsList = [];
    while (i < files.length) {
      itemsList[i] = files[i];
      i++;
    }
    itemsList = itemsList.map((item, index) => {
      function returnFileSize(number) {
        if (number < 1024) {
          return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
          return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
          return (number / 1048576).toFixed(1) + 'MB';
        }
      }
      function handleName(name) {
        if (name.length > 35) {
          return name.slice(0, 35) + ' ...';
        } else return name;
      }
      return (
        <div
          key={index}
          className="card"
          style={{
            width: '48%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 5,
              marginTop: 5,
              overflow: 'hidden'
            }}>
            <b>FileName: </b>
            <u>{handleName(item.name)}</u>
            <b>Size: </b>
            {returnFileSize(item.size)}
          </div>
          <div>
            <img
              alt=""
              src={URL.createObjectURL(item)}
              style={{ width: 100, height: 100, objectFit: 'cover' }}
            />
          </div>
        </div>
      );
    });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flexFlow: 'row wrap',
          alignContent: 'flex-end',
          justifyContent: 'space-between'
        }}>
        {itemsList}
      </div>
    );
  };
  const handleSelectStrategy = async (strategy) => {
    try {
      const response = await axios.get(`${API_GET_CS_IMAGES}/${strategy.id}`);
      setImages(response.data); // Suponiendo que la respuesta es un array con las imágenes
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
    }
  };
  let imgs = images;
  useEffect(() => {
    if (defaultData?.id) handleSelectStrategy(defaultData);
    else setImages([]);
  }, [defaultData]);
  const handleDeleteImage = async (imageToDelete) => {
    try {
      let config = {
        method: 'post',
        url: API_DELETE_CS_IMAGE,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          contentStrategyId: imageToDelete.PK,
          sourceUrl: imageToDelete.SK
        })
      };
      await axios(config);
      // Filtro las imágenes para eliminar la que coincide con la eliminada
      setImages(images.filter((image) => image.SK !== imageToDelete.SK));
    } catch (error) {
      console.error('Error deleting image:', error);
    } finally {
    }
  };
  return (
    <Modal
      id="modal-dialog-onboarding"
      backdrop="static"
      keyboard={false}
      show={showModal}
      onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title" id="addNewClientLabel">
          Images Manager - "{defaultData.strategyName}"
        </h5>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label
            style={{ margin: '0px' }}
            htmlFor="image_uploads"
            className="btn btn-sm  btn-primary mg-l-10"
            onClick={() => {
              setShowUploadModal(true);
            }}>
            Upload
          </label>
          <div></div>
          <Link to={{ pathname: `/images` }}>
            <span className="btn btn-sm  btn-primary mg-l-10">
              Go To Images Manager
            </span>
          </Link>
          <button
            onClick={() => {
              handleClose();
            }}
            className="btn-close"
            type="button"></button>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{
          height: 'calc(100vh - 200px)',
          overflowY: 'auto'
        }}>
        <form>
          <input
            style={{
              visibility: 'hidden',
              position: 'absolute'
            }}
            id="image_uploads"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
            type="file"
            multiple
            accept="image/png, image/jpeg"
          />
          {(files?.length && showUploadModal) > 0 ? (
            <div
              className="form-group"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <React.Fragment>
                <div className="modal-backdrop in">
                  <div
                    className="modal fade in show"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    style={{ display: 'block' }}>
                    <div
                      className="modal-dialog modal-dialog-centered modal-lg tx-2"
                      role="document">
                      <div className="modal-content tx-12">
                        <div className="modal-header">
                          <h5 className="modal-title" id="addNewGroupLabel">
                            Upload Images ({files?.length})
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setFiles({ length: 0 })}
                            disabled={uploadingImages}>
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div
                            className="card"
                            style={{
                              maxHeight: '500px',
                              minHeight: '325px',
                              overflow: 'scroll'
                            }}>
                            <div className="card-body">
                              <div>{renderImages(files)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            disabled={uploadingImages}
                            className="btn btn-danger tx-13"
                            data-dismiss="modal"
                            onClick={() => {
                              setShowUploadModal(false);
                            }}>
                            Cancel
                          </button>
                          <button
                            type="button"
                            disabled={uploadingImages || files?.length === 0}
                            className="btn btn-primary"
                            onClick={async () => {
                              setUploadingImages(true);
                              config.name = files[0].name;
                              setProgress(0);
                              let newArr = files;
                              for (let i = 0; i < newArr.length; i++) {
                                let location = await handleUpload(newArr[i]);
                                console.log('location:', location);
                                axios({
                                  method: 'post',
                                  url: UPDATE_STOCK_UPLOAD_IMAGES,
                                  data: {
                                    csId: defaultData?.id,
                                    sourceUrl: location,
                                    source: 'manual_upload',
                                    keywords: ''
                                  },
                                  headers: {
                                    'X-API-KEY': API_KEY,
                                    'Content-Type': 'application/json'
                                  }
                                });
                                if (i !== 0) {
                                  let pr = (i / files.length) * 100;
                                  setProgress(Math.round(pr));
                                } else {
                                  setProgress(0);
                                }
                              }
                              setTimeout(async () => {
                                setUploadingImages(false);
                                handleSelectStrategy(defaultData);
                                setFiles({});
                                setProgress(0);
                                swal.fire('Success!', 'Uploaded', 'success');
                              }, 3000);
                            }}>
                            <span
                              className={`spinner-border spinner-border-sm mr-1 ${
                                !uploadingImages ? 'hidden' : ''
                              }`}
                              role="status"
                              aria-hidden="true"></span>
                            {uploadingImages
                              ? 'Uploading...' +
                                `${progress !== 0 ? progress : '0'}%`
                              : 'Upload'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          ) : null}
        </form>
        <div className="card" style={{ minHeight: '59vh' }}>
          <div
            className="card-body"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              overflowX: 'hidden'
            }}>
            {imgs.map((img, idx) => {
              return (
                <Image
                  key={idx + img.SK}
                  style={{ width: '10vw', margin: '10px', height: '250px' }}
                  img={img}
                  onDeleteImage={(image) => handleDeleteImage(image)}
                  isModal={true}
                />
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <div>
            <span style={{ fontWeight: '500', fontSize: '16px' }}>
              Total Images: {imgs?.length}
            </span>
          </div>
          <button
            type="button"
            className="btn btn-secondary tx-13"
            data-dismiss="modal"
            onClick={() => {
              handleClose();
            }}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StrategyImages;
