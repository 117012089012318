export const showLoading = () => {
  return async (dispatch) => {
    dispatch({ type: 'loading/show' });
  };
};

export const hideLoading = () => {
  return async (dispatch) => {
    return dispatch({ type: 'loading/hide' });
  };
};
