import React, { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { accountManagers } from '../../../utils';

const TooltipInfo = ({ accountOwner, accountStatus }) => {
  const [isVisible, setIsVisible] = useState(false);

  const accountManager = (accountOwner) => {
    const accountManager = accountManagers.find(
      (manager) => manager.id === accountOwner
    );

    if (accountManager) {
      return accountManager.full_name;
    } else {
      return '';
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        marginLeft: '8px',
        height: '50px'
      }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}>
      {/* Icon "Info" */}
      <div
        style={{
          display: 'block',
          position: 'relative',
          top: 0
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = 'scale(1.1)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = 'scale(1)';
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14px"
          height="14px"
          viewBox="0 0 50 50">
          <InfoIcon style={{ color: '#3766a8' }} />
        </svg>
      </div>

      {/* Tooltip */}
      {isVisible && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '120%',
            transform: 'translateY(-50%)',
            backgroundColor: 'white',
            border: '1px solid #ddd',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            padding: '8px 12px',
            whiteSpace: 'nowrap',
            zIndex: 10
          }}>
          <p
            style={{
              margin: 0,
              fontSize: '14px',
              color: '#333'
            }}>
            <strong>Account Manager:</strong>{' '}
            {accountManager(accountOwner) || 'N/A'}
          </p>
          <p
            style={{
              margin: '4px 0 0 0',
              fontSize: '14px',
              color: '#333'
            }}>
            <strong>Account Status:</strong> {accountStatus || 'N/A'}
          </p>
        </div>
      )}
    </div>
  );
};

export default TooltipInfo;
