const initialState = {
  loading: false
};

export const loadingStateReducer = (state = initialState, action) => {
  switch (action?.type) {
    case 'loading/show':
      return {
        ...state,
        loading: true
      };
    case 'loading/hide':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
